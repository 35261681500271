<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비 -->
          <c-equip
            label="LBLEQUIP"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년월(예정, 완료) -->
          <c-datepicker
            label="LBL0003688"
            name="month"
            type="month"
            default="today"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckKindCd"
            label="LBL0003608"
            v-model="searchParam.equipmentCheckKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MIM_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 설비점검 목록 -->
    <c-table
      ref="table"
      title="LBL0003689"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      selection="multiple"
      rowKey="minEquipmentCheckId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 계획등록 -->
            <c-btn label="LBL0003690" v-if="editable" icon="add" @btnClicked="onItemClick('A')" />
            <!-- 결과등록 -->
            <c-btn label="LBL0003691" v-if="editable" icon="add" @btnClicked="onItemClick('B')" />
            <!-- 무계획결과등록 -->
            <c-btn label="LBL0003692" v-if="editable" icon="add" @btnClicked="onItemClick('C')" />
            <!-- 삭제 -->
            <c-btn label="LBLREMOVE" v-if="editable" icon="delete_forever" @btnClicked="remove" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['checkStatusCd']">
          <q-chip
            v-if="props.row.checkStatusCd ==='MCSC000020'"
            :color="setTagColor(props.row.checkStatusCd)"
            outline square
            class="full-size-chip checkStatusCd-blinking"
            text-color="white"
            @click="btnClicked(props.row)">
            <q-icon    v-if="props.row.checkStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
            {{setTagName(props.row.checkStatusCd)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "equipment-inspection",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          equipmentTypeCd: '',
          grade: '',
          inspectionCycleCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            // 사업장
            label: "LBLPLANT",
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            style: 'width:200px',
            // 설비명(관리번호)
            label: "LBL0003662",
            align: "left",
            sortable: false,
            type: "link",
          },
          {
            name: "checkStatusCd",
            field: "checkStatusCd",
            // 진행상태
            label: "LBLPROGRESS",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "checkScheduleDate",
            field: "checkScheduleDate",
            // 점검예정일
            label: "LBL0003603",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "checkScheduleUserName",
            field: "checkScheduleUserName",
            style: 'width:100px',
            // 점검예정자
            label: "LBL0003604",
            align: "center",
            sortable: false,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            // 점검부서
            label: "LBL0003605",
            align: "center",
            sortable: false,
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            // 점검완료일
            label: "LBL0003606",
            align: "center",
            sortable: false,
          },
          {
            name: "checkResultName",
            field: "checkResultName",
            style: 'width:80px',
            // 점검결과
            label: "LBL0003607",
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCheckKindNm",
            field: "equipmentCheckKindNm",
            // 점검유형
            label: "LBL0003608",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            // 점검주기
            label: "LBL0003609",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0003610",
            style: 'width:250px',
            align: "left",
            sortable: false,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            // 계획/무계획
            label: "LBL0003611",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        equipmentCheckKindCd: null,
        checkStatusCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        equipmentTypeCd: '',
        inspectionCycleCd: '',
        grade: '',
        equipmentCd: '',
        month: '',
      },
      period: [ '', ''],
      editable: true,
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.min.equipment.plan.list.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.result.delete.url;
      // list setting
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd;
        this.searchParam.inspectionCycleCd = this.popupParam.inspectionCycleCd;
        this.searchParam.grade = this.popupParam.grade;
      }
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0003620'; // 설비점검결과 상세
      this.popupOptions.param = {
        minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        title: '설비점검결과',
        selectData: [],
        noPlan: 'N',
      };
      this.popupOptions.target = () => import(`${"./equipmentResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(data) {
      let selectData = this.$refs['table'].selected;
      if (data === 'B' || data === 'E') {
        if (selectData && selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // '안내',
            message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
            type: 'warning',
          });
          return;
        }
      }
      let title = '';
      let param = null;

      if (data === 'A') {
        param = {
          title: "설비점검계획",
          selectData: [],
        };
        title = 'LBL0003693'; // 설비점검계획 상세
        this.popupOptions.target = () => import(`${"./equipmentPlan.vue"}`);
        this.popupOptions.width = '95%';
        this.popupOptions.isFull = false;
      }
      if (data === 'B') {
        param = {
          title: "설비점검결과",
          noPlan: 'N',
          multiple: true,
          selectData: selectData ? selectData: [],
        };
        title = 'LBL0003620'; // 설비점검결과 상세
        this.popupOptions.target = () => import(`${"./equipmentResultDetail.vue"}`);
        this.popupOptions.isFull = true;
      }
      if (data === 'C') {
        param = {
          title: "무계획결과",
          noPlan: 'Y',
          minEquipmentCheckId: '',
        };
        title = 'LBL0003694'; // 무계획결과 상세
        this.popupOptions.target = () => import(`${"./equipmentResult.vue"}`);
        this.popupOptions.isFull = true;
      }
      this.popupOptions.title = title; // 설비점검계획 상세
      this.popupOptions.param = param;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          selectData = this.$_.reject(selectData, { checkStatusCd: 'MCSC000015' })
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request((_result) => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { minEquipmentCheckId: item.minEquipmentCheckId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
<style>
.checkStatusCd-blinking {
  -webkit-animation:checkStatusCd-blink 2s ease-in-out infinite alternate;
  -moz-animation:checkStatusCd-blink 2s ease-in-out infinite alternate;
  animation:checkStatusCd-blink 2s ease-in-out infinite alternate;
}
@-webkit-keyframes checkStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes checkStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes checkStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>